/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconAngry = ({ className, color = "#f5846d" }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10.5"
        cy="10"
        r="8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 14C6.5 14.5523 6.94772 15 7.5 15C8.05228 15 8.5 14.5523 8.5 14L6.5 14ZM7.72836 12.8519L6.80448 12.4693L7.72836 12.8519ZM8.37868 11.8787L7.67157 11.1716L7.67157 11.1716L8.37868 11.8787ZM9.35195 11.2284L9.73463 12.1522L9.73463 12.1522L9.35195 11.2284ZM12.6213 11.8787L13.3284 11.1716L13.3284 11.1716L12.6213 11.8787ZM13.2716 12.8519L14.1955 12.4693L14.1955 12.4693L13.2716 12.8519ZM12.5 14C12.5 14.5523 12.9477 15 13.5 15C14.0523 15 14.5 14.5523 14.5 14L12.5 14ZM8.5 14C8.5 13.7374 8.55173 13.4773 8.65224 13.2346L6.80448 12.4693C6.60346 12.9546 6.5 13.4747 6.5 14L8.5 14ZM8.65224 13.2346C8.75275 12.992 8.90007 12.7715 9.08579 12.5858L7.67157 11.1716C7.30014 11.543 7.0055 11.984 6.80448 12.4693L8.65224 13.2346ZM9.08579 12.5858C9.2715 12.4001 9.49198 12.2527 9.73463 12.1522L8.96927 10.3045C8.48396 10.5055 8.04301 10.8001 7.67157 11.1716L9.08579 12.5858ZM9.73463 12.1522C9.97728 12.0517 10.2374 12 10.5 12L10.5 10C9.97471 10 9.45457 10.1035 8.96927 10.3045L9.73463 12.1522ZM10.5 12C10.7626 12 11.0227 12.0517 11.2654 12.1522L12.0307 10.3045C11.5454 10.1035 11.0253 10 10.5 10L10.5 12ZM11.2654 12.1522C11.508 12.2528 11.7285 12.4001 11.9142 12.5858L13.3284 11.1716C12.957 10.8001 12.516 10.5055 12.0307 10.3045L11.2654 12.1522ZM11.9142 12.5858C12.0999 12.7715 12.2472 12.992 12.3478 13.2346L14.1955 12.4693C13.9945 11.984 13.6999 11.543 13.3284 11.1716L11.9142 12.5858ZM12.3478 13.2346C12.4483 13.4773 12.5 13.7374 12.5 14L14.5 14C14.5 13.4747 14.3965 12.9546 14.1955 12.4693L12.3478 13.2346Z"
        fill={color}
      />
      <path
        d="M7.5 7L8.5 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M13.5 7L12.5 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
