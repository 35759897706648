import HttpClient from "../../utils/HttpClient";

import _ from "lodash";

export default class MasterClassRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getMasterClassActivityTail(queryString?: string) {
    return this.instance.get("masterClassesView" + (queryString ?? ""));
  }

  public getMasterClass(masterClassId?: number) {
    return this.instance.get("/masterClasses/masterclass/" + masterClassId);
  }

  public getAllMasterClasses() {
    const path = "/masterClasses";
    return this.instance.get(path);
  }
}
