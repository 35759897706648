import { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import {
  OfficeCoworking,
  getOfficeCoworkingAsync,
  officeCoworkingSelector,
} from "./OfficeCoworkingSlice";
import { Faq, faqSelector, getOfficeCoworkingFaqsAsync } from "../Faq/FaqSlice";
import {
  Gallery,
  gallerySelector,
  getOfficeCoworkingGalleryAsync,
} from "../Gallery/GallerySlice";
import { store } from "../../app/store";
import { Map } from "../../ui/components/Map/Map";
import HeaderMenu from "../../layout/HeaderMenu";
import { useAppSelector } from "../../app/hooks";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import { t } from "i18next";
import { AssistanceBoxComponent } from "../../ui/components/AssistanceBoxComponent";
import QuestionsAndAnswers from "../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import { OfficeCoworkingSummary } from "./OfficeCoworkingSummary";
import { TwentyIcArrowLeft } from "../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowRight } from "../../ui/components/icons/TwentyIcArrowRight";
import {
  OfficeCoworkingIncludedServices,
  getOfficeCoworkingIncludedServicesAsync,
  officeCoworkingIncludedServicesSelector,
} from "./OfficeCoworkingIncludedServicesSlice";
import "./OfficeCoworkingDetails.css";
import locale from "antd/es/date-picker/locale/it_IT";
import "dayjs/locale/it";
import { DatePicker } from "antd";
import { Footer } from "../../screens/Footer/Footer";
import { TwentyIcCalendarSeet } from "../../ui/components/icons/TwentyIcCalendarSeet";
import { Button } from "../../ui/components/Button/Button";
import dayjs from "dayjs";
import { OfficeCoworkingPurchaseModal } from "./OfficeCoworkingPurchaseModal";
import ChargeCreditsModal from "../CreditManagement/Admin/ChargeCreditsModal";

export const OfficeCoworkingDetails = () => {
  const [id, setId] = useState<number>(-1);
  const officeCoworkingRoute = useMatch("officeCoworking/:id/details");
  const navigate = useNavigate();

  let officeCoworking: OfficeCoworking = useAppSelector(
    (state) =>
      officeCoworkingSelector.selectById(state, id) ?? ({} as OfficeCoworking)
  );

  let faq: Faq[] = useAppSelector(faqSelector.selectAll);
  let incluldedServices: OfficeCoworkingIncludedServices = useAppSelector(
    officeCoworkingIncludedServicesSelector.selectAll
  )[0];
  let gallery: Gallery[] = useAppSelector(gallerySelector.selectAll);
  const imageList = gallery.map((el) => el.image);
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [creditModalStatus, setCreditModalStatus] = useState(false);

  dayjs.locale("it");

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];
  const orderMatched = useMatch("officeCoworking/:id/details/order");

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const [purchaseModalStatus, setPurchaseModalStatus] = useState(false);

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (isGalleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? imageList.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === imageList.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isGalleryOpen, imageList, currentImageIndex]);

  useEffect(() => {
    if (officeCoworkingRoute !== null) {
      if (officeCoworkingRoute.params.id) {
        setId(parseInt(officeCoworkingRoute.params.id));
        store.dispatch(
          getOfficeCoworkingAsync(parseInt(officeCoworkingRoute.params.id))
        );
        store.dispatch(
          getOfficeCoworkingFaqsAsync({
            officeCoworkingId: parseInt(officeCoworkingRoute.params.id),
          })
        );
        store.dispatch(
          getOfficeCoworkingGalleryAsync({
            officeCoworkingId: parseInt(officeCoworkingRoute.params.id),
          })
        );
        store.dispatch(
          getOfficeCoworkingIncludedServicesAsync(
            parseInt(officeCoworkingRoute.params.id)
          )
        );
        return;
      }
    }
  }, [officeCoworkingRoute]);

  const handleStarDateChange = (e: any) => {
    if (endDate && e <= endDate) setStartDate(e);
  };

  const handleEndDate = (e: any) => {
    if (startDate && e >= startDate) setEndDate(e);
  };

  const handleClickCheck = () => {
    const elementToScrollTo = document.getElementById("checkBooking");
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={
            officeCoworking &&
            officeCoworking.city + " • " + officeCoworking.region
          }
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            officeCoworking &&
            "Coworking > " +
              officeCoworking.city +
              " • " +
              officeCoworking.region
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/officeCoworking")}
        />
      </HeaderMenu>
      <div className="smartplandetail-container">
        <img
          className="rectangle-8"
          alt=""
          src={(officeCoworking && officeCoworking.image) ?? undefined}
        />
        <div className="overlap-2">
          <div className="smartplandetail-bady-page">
            {/* region Services */}
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>
            <div className="office-coworking-services-container">
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"WI-FI"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.wifi ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"FIBRA"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.fiber ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"STAMPANTE"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.printer ? "Sì" : "No"}
                </div>
              </div>

              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"FOTOCOPIATRICE"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.copyPrinter ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"ARIA CONDIZIONATA"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.airConditioning ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"PARCHEGGIO"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.park ? "Sì" : "No"}
                </div>
              </div>

              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"DEPOSITO BICICLETTE"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.bicyclePark ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"VIDEOPROIETTORE"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.videoProjector ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"SEGRETERIA"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.secretarian ? "Sì" : "No"}
                </div>
              </div>

              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"CUCINA"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.kitchen ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"CAFFE'"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.coffeMachine ? "Sì" : "No"}
                </div>
              </div>
              <div className="service-container-row-element">
                <div
                  className="office-coworking-service-title"
                  style={{ whiteSpace: "normal" }}
                >
                  {"FRIGORIFERO"}
                </div>
                <div className="service-subtitle">
                  {incluldedServices?.refrigerator ? "Sì" : "No"}
                </div>
              </div>
            </div>
            {/* endregion Services */}
            {/* region Gallery */}
            <div className="title-gallery">{t("smartPlanDetail.gallery")}</div>
            <div className="smart-plan">
              {" "}
              <div className="flex-container-gallery">
                {gallery &&
                  gallery.map((item, index) => (
                    <div className="gallery-container" key={item.id}>
                      <img
                        className="gallery-image"
                        src={item.image}
                        aria-hidden
                        alt={`Image ${item.id}`}
                        onClick={() => openGallery(index)}
                      />
                    </div>
                  ))}
              </div>
              {isGalleryOpen && (
                <div className="gallery-overlay">
                  <button className="close-button" onClick={closeGallery}>
                    x
                  </button>
                  <button
                    className="prev-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === 0 ? imageList.length - 1 : prev - 1
                      )
                    }
                  >
                    <TwentyIcArrowLeft className="arrow-gallery" />
                  </button>
                  <button
                    className="next-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === imageList.length - 1 ? 0 : prev + 1
                      )
                    }
                  >
                    <TwentyIcArrowRight
                      className="arrow-gallery"
                      onClick={undefined}
                    />
                  </button>
                  <div className="gallery">
                    <img
                      src={imageList[selectedImageIndex]}
                      alt="img"
                      className="gallery-image-large"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* endregion Gallery */}
            {/* region Description */}
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <div className="description-smartpaln">
              <div
                dangerouslySetInnerHTML={{
                  __html: officeCoworking?.description ?? "",
                }}
              />
            </div>
            <div className="text-wrapper-21-map">
              {t("smartPlanDetail.map")}
            </div>
            {/* endregion Description */}
            {/* regin Map */}
            <Map city={officeCoworking && officeCoworking.city} />
            {/* endregion Map */}
            <div
              className="office-coworking-booking-check-section"
              id="checkBooking"
            >
              <div className="text-wrapper-21-description">
                <div className="text-wrapper-21-service">
                  Verifica disponibilità
                </div>
              </div>
              <div className="office-coworking-booking-check-section-datepicker">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  Data di inizio
                  <DatePicker
                    locale={locale}
                    allowClear={false}
                    value={startDate}
                    onChange={handleStarDateChange}
                    placeholder=""
                    format={dateFormatList}
                    suffixIcon={
                      <TwentyIcCalendarSeet
                        color="#36B39E"
                        className={undefined}
                      ></TwentyIcCalendarSeet>
                    }
                    size="large"
                    style={{ width: "100%", height: "35px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  }}
                >
                  Data di fine
                  <DatePicker
                    allowClear={false}
                    locale={locale}
                    onChange={handleEndDate}
                    value={endDate}
                    placeholder=""
                    format={dateFormatList}
                    suffixIcon={
                      <TwentyIcCalendarSeet
                        color="#36B39E"
                        className={undefined}
                      ></TwentyIcCalendarSeet>
                    }
                    size="large"
                    style={{ width: "100%", height: "35px" }}
                  />
                </div>
              </div>
              <Button
                type="primary"
                size="XL"
                state="default"
                onClick={() => {}}
                text={"Verifica disponibilità"}
                iconRight={undefined}
                icon={undefined}
                disabled={undefined}
              />
            </div>
            {/* regin Faq */}
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t("smartPlanDetail.questionsAndAnswers.questionsAndAnswers")}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer-smartplan">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div className="answer-smartplan">
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
            {/* endregin Faq */}
          </div>{" "}
          <div className="container-summary">
            <div className="summary-wrapper">
              <OfficeCoworkingSummary
                purchasing={() => {
                  setPurchaseModalStatus(!purchaseModalStatus);
                }}
                daysPurchasing={undefined}
                voucherPrice={officeCoworking.dailyPrice}
                email={officeCoworking.email}
                isBuying={orderMatched?.params.id ? true : false}
                telephone={officeCoworking.telephone}
                type={""}
                goToBooks={() => handleClickCheck()}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "smartPlanDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                date={t("smartPlanDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", "firstName" ?? "")
                  .replace("lastName*", "lastName" ?? "")
                  .replace("companyName*", "businessName" ?? "")}
                disabled={undefined}
              />
            </div>
          </div>
        </div>
        {purchaseModalStatus && (
          <OfficeCoworkingPurchaseModal
            creditModalStatus={creditModalStatus}
            creditModal={(value) => {
              setCreditModalStatus(value);
            }}
            modalClosed={(value) => {
              setPurchaseModalStatus(value);
            }}
            modalStatus={purchaseModalStatus}
            purchasePrice={officeCoworking.dailyPrice}
          ></OfficeCoworkingPurchaseModal>
        )}
        {creditModalStatus && (
          <ChargeCreditsModal
            onClose={() => {
              setCreditModalStatus(!creditModalStatus);
            }}
            title="Ricarica crediti"
          ></ChargeCreditsModal>
        )}
      </div>
      <Footer />
    </>
  );
};
