import { Select } from "antd";
import { BaseOptionType } from "antd/es/select";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { store } from "../../../app/store";
import { TeamUserInfo } from "../../../feature/MyTeam/TeamUsersInfoSlice";
import { UserInfo } from "../../../feature/UserInfo/UserSlice";
import {
  createAssociationAsync,
  deleteAssociationAsync,
} from "../../../feature/myorders/MyOrderDetailSlice";
import Tag from "../Tag/Tag";
import "./AssignedPeople.css";

export interface AssignedPeopleProps {
  bookingId: number;
  numberOfPeople?: number;
  users: TeamUserInfo[];
  currentUsers: UserInfo[];
}

const AssignedPeople: React.FC<AssignedPeopleProps> = ({
  bookingId,
  currentUsers,
  users,
  numberOfPeople,
}) => {
  const [usersSelected, setUsersSelected] = useState<string[]>([]);

  function tagRender(props: any) {
    const { label, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  const handleSelect = (label: BaseOptionType) => {
    store.dispatch(
      createAssociationAsync({
        bookingId: bookingId,
        queryString: label.value,
      })
    );
  };

  const handleDeselect = (label: BaseOptionType) => {
    store.dispatch(
      deleteAssociationAsync({
        bookingId: bookingId,
        queryString: label.value,
      })
    );
  };

  let usersOptions: BaseOptionType["users"] = users.map((user) => {
    return {
      label: user.fullName,
      value: user.id,
    };
  });

  let currentUsersOptions: BaseOptionType["currentUsers"] = currentUsers.map(
    (user) => {
      return {
        label: user.firstname + " " + user.lastname,
        value: user.id,
      };
    }
  );

  const onChange = (value: string[]) => {
    if (numberOfPeople && value.length <= numberOfPeople)
      setUsersSelected(value);
  };

  useEffect(() => {
    setUsersSelected(currentUsersOptions);
  }, []);

  return (
    <>
      {/* <div className="orderdetail">
        <div className="people-container-add">
          <div className="orderdetail-title">{"Persone assegnate"}</div>
          {usersSelected.length +
            " di " +
            numberOfPeople +
            " persone assegnate"}
          <div className="people-searchbar">
            <Select
              value={usersSelected}
              tagRender={tagRender}
              placeholder={t("Aggiungi persone")}
              bordered={false}
              mode="multiple"
              allowClear
              onSelect={(value, option) => {
                handleSelect(option);
              }}
              onDeselect={(value, option) => {
                handleDeselect(option);
              }}
              style={{
                paddingTop: "2px",
                paddingBottom: "4px",
                gap: "5px",
                alignItems: "center",
                width: "100%",
                minHeight: "40px",
                height: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
              onChange={onChange}
              options={usersOptions}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AssignedPeople;
