import React, { useEffect, useState } from "react";
import "./Footer.css";

// Definiamo i possibili ruoli come tipo stringa
type UserRole = "admin" | "provider" | "employee" | null;

export const Footer = () => {
  // Stato per memorizzare il ruolo dell'utente (con tipo UserRole)
  const [userRole, setUserRole] = useState<UserRole>(null);

  // Eseguiamo questo codice quando il componente viene montato
  useEffect(() => {
    // Recuperiamo il ruolo dell'utente dal localStorage
    const role = localStorage.getItem("securityProfile");

    // Verifica se il ruolo è ADMIN o PROVIDER
    if (role === "ADMIN") {
      setUserRole("admin");
    } else if (role === "PROVIDER") {
      setUserRole("provider");
    } else if (role === "EMPLOYEE") {
      setUserRole("employee");
    } else {
      setUserRole(null); // Se il ruolo non è riconosciuto, settiamo null
    }
  }, []);

  // Funzione per determinare il link dei "Termini e Condizioni" in base al ruolo
  const getTermsAndConditionsLink = (): string => {
    if (userRole === "provider") {
      return "https://everywheresb.it/termini-e-condizioni-piattaforma/"; // Link per il fornitore
    } else if (userRole === "admin" || userRole === "employee") {
      // Qui controlliamo se userRole è "admin" OPPURE "employee"
      return "https://everywheresb.it/termini-e-condizioni-aziende/"; // Link per admin/dipendente
    } else {
      return ""; // Link vuoto se non ci sono ruoli validi
    }
  };

  return (
    <div className="footer">
      {/* Copyright */}
      <div className="footer-copyright">© EverywhereSB, s.r.l</div>
      
      {/* Contatti */}
      <div className="footer-label">contact@everywheretew.it</div>

      {/* Link Privacy Policy */}
      <a
        href="https://everywheretew.it/privacy-policy-piattaforma"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="footer-label">Privacy Policy</div>
      </a>

      {/* Link Termini e Condizioni con comportamento condizionato in base al ruolo */}
      {userRole && (
        <a
          href={getTermsAndConditionsLink()} // Qui inseriamo dinamicamente il link
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer-label">Termini e condizioni</div>
        </a>
      )}
    </div>
  );
};
