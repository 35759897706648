import "../../../feature/Smartplan/SmartplanDetails/SmartPlanDetails.css";
import React from "react";
import { TwentyIcFlag1 } from "../TwentyIcFlag1";
import { TwentyIcPinMap1 } from "../TwentyIcPinMap1";
import { TwentyIcInfo } from "../icons/TwentyIcInfo";
import { Button } from "../Button/Button";
import { TwentyIcArrowLineLeft } from "../icons/TwentyIcArrowLineLeft/TwentyIcArrowLineLeft";
import { TwentyIcCreditCoins } from "../TwentyIcCreditCoins/TwentyIcCreditCoins";
import { useLocation } from "react-router-dom";

interface SummaryComponentsProps {
  startDate: string;
  endDate: string;
  destinationText: string;
  activityText: string;
  price: number;
  summary: string;
  start: string;
  end: string;
  destination: string;
  activity: string;
  startingFrom: string;
  downPayment: string;
  descriptionP1: string;
  descriptionP2: string;
  selectDate: string;
  region: string;
  province: string;
  onClick: () => any;
  goToBooks?: () => any;
}

const compareDates = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return (
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate()
  );
};

export const SummaryComponent: React.FC<SummaryComponentsProps> = ({
  startDate,
  endDate,
  destinationText,
  activityText,
  price,
  summary,
  start,
  end,
  destination,
  activity,
  startingFrom,
  downPayment,
  descriptionP1,
  descriptionP2,
  selectDate,
  province,
  region,
  onClick,
  goToBooks,
}) => {
  // Utilizzo del useLocation per determinare il percorso corrente
  const location = useLocation();
  const isPreview = location.pathname.includes("previewService");

  return (
    <div className="frame-17">
      <div className="text-wrapper-11">{summary}</div>
      <div className="rectangle-9" />
      {start && end && (
        <div className="frame-18">
          <div className="frame-19">
            <div className="text-wrapper-12">{start}</div>
            <div className="frame-20">
              <img
                className="icon-instance-node-3"
                alt="Arrow back"
                src="https://generation-sessions.s3.amazonaws.com/fcd00ec9170104c6931644d38af9293a/img/arrow-back.svg"
              />
              <div className="text-wrapper-13">
                {compareDates(startDate, endDate) ? startDate : "Da definire"}
              </div>
            </div>
          </div>
          <div className="frame-21">
            <div className="text-wrapper-12">{end}</div>
            <div className="frame-20">
              <img
                className="icon-instance-node-3"
                alt="Arrow back"
                src="https://generation-sessions.s3.amazonaws.com/fcd00ec9170104c6931644d38af9293a/img/arrow-back-1.svg"
              />
              <div className="text-wrapper-13">
                {compareDates(startDate, endDate) ? endDate : "Da definire"}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="frame-22-summary">
        <div className="text-wrapper-14">{destinationText}</div>
        <div className="frame-20">
          <TwentyIcPinMap1 className="icon-instance-node-3" />
          {!destination || !region ? (
            <div className="text-wrapper-15">Da definire</div>
          ) : (
            <div className="text-wrapper-15">
              {destination + " • " + region}
            </div>
          )}
        </div>
      </div>
      <div className="rectangle-9" />
      <div className="frame-23">
        <div className="text-wrapper-14">{startingFrom}</div>
        <div className="frame-24">
          <p className="p">{downPayment}</p>
        </div>
      </div>
      <h1 className="h-1" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <span className="text-wrapper-14">Costo</span>
  <div style={{ display: "flex", alignItems: "center" }}>
    {price}
    <div style={{ position: "relative", top: "4px", marginLeft: "5px" }}>
      {!isPreview && (
        <TwentyIcCreditCoins
          className="credit-icon"
          color="#36B39E"
          colorC="#36B39E"
        />
      )}
    </div>
  </div>
</h1>
      <Button
        type="secondary"
        size="XL"
        state="default"
        onClick={() => {
          if (goToBooks) {
            goToBooks();
          }
          if (onClick) {
            onClick();
          }
        }}
        text={selectDate}
        disabled={undefined}
        iconRight={undefined}
        icon={undefined}
      />
      <div className="frame-25">
        <TwentyIcInfo className="icon-instance-node-3" color="#14ADCC" />
        <p className="prenotazioni-min">
          <span className="span">{descriptionP1}</span>
          <span className="text-wrapper-16">{descriptionP2}</span>
        </p>
      </div>
    </div>
  );
};
